<template lang="pug">
  v-card-content
    v-date-field(
      :date-from="filterMinDate"
      :date-to="filterMaxDate"
      :label="$t('base.date')"
      v-model="form.planned_at")

    v-select.mb-3(
      :label="$t('base.ration')"
      :options="rationsList"
      v-model="form.ration_id")

    v-select.mb-3(
      :label="$t('base.recipe')"
      :options="recipes"
      v-model="form.recipe_id")

    v-switch.mr-1.mb-1(
        :label="$t('modals.planning.create.require_qr_code')"
        v-model="form.require_qr_code")

    v-switch.mr-1.mb-1(
        :label="$t('modals.planning.create.require_queue')"
        v-model="form.require_queue")

    v-text-field.mb-2(
      :label="$t('base.weight')"
      v-model="form.planned_weight")

    v-text-field.mb-2(
      :label="$t('pages.settings.mixing_time')"
      v-model="form.mixing_time")

    v-card-actions
      v-btn(
        :disabled="!isRationValid || !isDateSelected || !isRecipeSelected || !isWeightSelected"
        :loading="loading"
        @click="onCreate") {{ 'actions.create_schedule' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreatePeriodModal',

  props: {
    params: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    form: {
      ration_id: null,
      recipe_id: null,
      planned_at: null,
      require_qr_code: false,
      require_queue: false,
      mixing_time: 0,
      planned_weight: 0
    },
    loading: false
  }),

  computed: {
    ...mapGetters([
      'recipesList',
      'rationsList'
    ]),

    isRationValid () {
      return !!this.form.ration_id
    },

    isDateSelected () {
      return this.form.planned_at !== null
    },

    isRecipeSelected () {
      return this.form.recipe_id !== null
    },

    isWeightSelected () {
      return this.form.planned_weight > 0
    },

    recipes () {
      return this.recipesList.filter(item => item.ration_id === this.form.ration_id)
    },

    filterMinDate () {
      return new Date()
    },

    filterMaxDate () {
      return new Date(new Date().setDate(new Date().getDate() + 7))
    }
  },

  async mounted () {
    this.form.planned_at = this.params.planned_on
    await this.fetchRationsList()
    await this.fetchRecipesList({ status: true })
  },

  methods: {
    ...mapActions([
      'fetchRecipesList',
      'fetchRationsList',
      'createTimetableSchedule'
    ]),

    async onCreate () {
      this.loading = true
      const { errors } = await this.createTimetableSchedule(this.form)
      this.loading = false
      if (!errors) {
        this.$emit('close')
      } else {
        for (const key in errors) {
          for (const msg of errors[key]) {
            this.$toasted.show(msg, {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .cols {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .col {
      &:first-child {
        width: calc(70% - 10px);
      }

      &:last-child {
        display: flex;
        align-items: center;
        width: calc(30% - 10px);
      }
    }
  }
  .v-switch__wrapper {
    margin: 10px 0;
  }
</style>
